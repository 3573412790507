import { computed } from "vue";
import {
  clientPhasesNames,
  phasesNames,
  phaseValidationStatus,
} from "@/constants/dossier.structure";

export default function useClientPhases(dossier) {
  const clientPhasesStatusesEquivalents = computed(() => ({
    [clientPhasesNames.PHASE_1.key]: [dossier.phasesStatuses[phasesNames.PHASE_1.key].status],
    [clientPhasesNames.PHASE_2.key]: [
      dossier.phasesStatuses[phasesNames.PHASE_2.key].status,
      dossier.phasesStatuses[phasesNames.PHASE_3.key].status,
      dossier.phasesStatuses[phasesNames.PHASE_4.key].status,
    ],
    [clientPhasesNames.PHASE_3.key]: [
      dossier.phasesStatuses[phasesNames.PHASE_5.key].status,
      dossier.phasesStatuses[phasesNames.PHASE_6.key].status,
    ],
    [clientPhasesNames.PHASE_4.key]: [dossier.phasesStatuses[phasesNames.PHASE_7.key].status],
    [clientPhasesNames.PHASE_5.key]: [dossier.phasesStatuses[phasesNames.PHASE_8.key].status],
  }));

  const firstPhasesNotValid = computed(() => {
    const phase1NotValid = clientPhasesStatusesEquivalents.value[
      clientPhasesNames.PHASE_1.key
    ].find(status => status !== phaseValidationStatus.VALID.key);
    const phase2NotValid = clientPhasesStatusesEquivalents.value[
      clientPhasesNames.PHASE_2.key
    ].find(status => status !== phaseValidationStatus.VALID.key);

    return phase1NotValid || phase2NotValid;
  });

  const secondPhasesNotValid = computed(() => {
    return clientPhasesStatusesEquivalents.value[clientPhasesNames.PHASE_3.key].find(
      status => status !== phaseValidationStatus.VALID.key
    );
  });

  const processedClientPhasesNames = computed(() => {
    if (firstPhasesNotValid.value) {
      return Object.values(clientPhasesNames).filter(clientPhase => {
        return (
          clientPhase.key === clientPhasesNames.PHASE_1.key ||
          clientPhase.key === clientPhasesNames.PHASE_2.key
        );
      });
    } else if (secondPhasesNotValid.value) {
      return Object.values(clientPhasesNames).filter(clientPhase => {
        return (
          clientPhase.key === clientPhasesNames.PHASE_1.key ||
          clientPhase.key === clientPhasesNames.PHASE_3.key ||
          clientPhase.key === clientPhasesNames.PHASE_2.key
        );
      });
    } else {
      return Object.values(clientPhasesNames);
    }
  });

  const phase1NotValid = computed(() => {
    return clientPhasesStatusesEquivalents.value[clientPhasesNames.PHASE_1.key].find(
      status => status !== phaseValidationStatus.VALID.key
    );
  });

  const phase2NotValid = computed(() => {
    return clientPhasesStatusesEquivalents.value[clientPhasesNames.PHASE_2.key].find(
      status => status !== phaseValidationStatus.VALID.key
    );
  });

  const phase3NotValid = computed(() => {
    return clientPhasesStatusesEquivalents.value[clientPhasesNames.PHASE_3.key].find(
      status => status !== phaseValidationStatus.VALID.key
    );
  });

  const phase4NotValid = computed(() => {
    return clientPhasesStatusesEquivalents.value[clientPhasesNames.PHASE_4.key].find(
      status => status !== phaseValidationStatus.VALID.key
    );
  });

  const phase5NotValid = computed(() => {
    return clientPhasesStatusesEquivalents.value[clientPhasesNames.PHASE_5.key].find(
      status => status !== phaseValidationStatus.VALID.key
    );
  });

  const firstNotValidPhase = computed(() => {
    if (phase1NotValid.value) {
      return clientPhasesNames.PHASE_1.urlKey;
    } else if (phase2NotValid.value) {
      return clientPhasesNames.PHASE_2.urlKey;
    } else if (phase3NotValid.value) {
      return clientPhasesNames.PHASE_3.urlKey;
    } else if (phase4NotValid.value) {
      return clientPhasesNames.PHASE_4.urlKey;
    } else if (phase5NotValid.value) {
      return clientPhasesNames.PHASE_5.urlKey;
    }

    return clientPhasesNames.PHASE_5.urlKey;
  });

  return {
    clientPhasesStatusesEquivalents,
    processedClientPhasesNames,
    firstNotValidPhase,
  };
}
